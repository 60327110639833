import axios from "axios";
import { useEffect, useState } from "react";
import { image } from "./../assets";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Grid';

export function View(props) {
  const [data, setData] = useState([]);

  useEffect(async () => {
    let response = null;
    try {
      response = await axios.get("https://cfassign.nisshah1499.workers.dev/posts");
      // format and restructure data if required
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
      console.log("Error in fetching data");
    }
  }, []);

  useEffect(async () => {
    let response = null;
    try {
      response = await axios.get("https://cfassign.nisshah1499.workers.dev/posts");
      // format and restructure data if required
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.log(error);
      console.log("Error in fetching data");
    }
  }, [props.newEntry]);

  const dummy = [
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    },
    {
      username: "Test2",
      title: "Title2",
      content: "Content2",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    },
    {
      username: "Test2",
      title: "Title2",
      content: "Content2",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Hey I am Nisarg Shah SHah SHah Shah...Hey I am Nisarg Shah SHah SHah Shah...",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    },
    {
      username: "Test2",
      title: "Title2",
      content: "Content2",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    },
    {
      username: "Test1",
      title: "Title1",
      content: "Content1",
      image: image,
    }
  ];

  return (
    <div>
      {data.map != null ? (
        <div>
          <p>List of posts!</p>
          <Grid container spacing={3}>
          {data.map((d, i) => (
            <Grid item md={3}>
            <Card sx={{ maxWidth: 345 }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    {d.username.charAt(0)}
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={d.title}
                subheader={d.username}
              />
              {d.image ? 
                <CardMedia
                component="img"
                height="194"
                image={d.image}
                alt="Paella dish"
              />
              : null}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {d.content}
                </Typography>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
            </Grid>
          ))}
          </Grid>
        </div>
      ) : (
        <div>No posts to display</div>
      )}
    </div>

  );
}



    


// return (
//     <div>
//       {dummy.map != null ? (
//         <div>
//           <p>List of posts!</p>
//           {dummy.map((d, i) => (
//             <div style={{ marginTop: "10px", marginBottom: "10px" }}>
//               <div>Username: {d.username}</div>
//               <div>Title: {d.title}</div>
//               <div>Content: {d.content}</div>
//               {d.image ?
//               <div>
//                 <img src={d.image}></img>
//               </div>
//                : null}
//             <hr />
//             </div>
//           ))}
//         </div>
//       ) : (
//         <div>No posts to display</div>
//       )}
//     </div>

//   );