import "./App.css";
import { useState } from "react";
import { Add } from "./components/add";
import { View } from "./components/view";

function App() {
  const [newEntry, setNewEntry] = useState({});

  function updateNewEntry(params){
    setNewEntry({
      username: params.username,
      title: params.title,
      content: params.content,
      image: params.image
    });
  }

  return (
    <div className="App">
      <Add updateFunc = {updateNewEntry}/>
      <hr
        style={{
          color: "black",
          backgroundColor: "black",
          height: 5,
        }}
      />
      <View newEntry = {newEntry}/>
    </div>
  );
}

export default App;
