import Reacț, { useState } from "react";
import axios from "axios";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

export function Add(props) {
  // states
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState();
  const [message, setMessage] = useState("");

  const onClick = async () => {
    const data = {
      username,
      title,
      content,
      image,
    };

    let response = null;
    try {
      response = await axios.post("https://cfassign.nisshah1499.workers.dev/posts", data);
      if (response.status === 200) {
        props.updateFunc(data);
        setUsername("");
        setTitle("");
        setContent("");
        setImage();
        console.log("Post Success");
        setMessage("Data Posted!");
      } else {
        console.log("Error in posting");
        setMessage("Error in posting data!");
      }
    } catch (error) {
      console.log("Error in posting");
      setMessage("Error in posting data!");
    }
    // clear message
    setTimeout(() => {
      setMessage("");
    }, 5000);
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    try {
      const base64 = await convertBase64(file);
      console.log(base64);
      setImage(base64);
    } catch (error) {
      console.log("Error in image upload");
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <div>
      <div>
        <TextField id="outlined-basic" label="Enter username" style={{ marginLeft: "20px", marginTop: "20px" }} variant="outlined" value = {username} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div>
      <TextField id="outlined-basic" label="Enter title" style={{ marginLeft: "20px", marginTop: "20px" }} variant="outlined" value={title}
          onChange={(e) => setTitle(e.target.value)} />
      </div>
      <div>
      <TextField id="outlined-basic" label="Enter content" style={{ marginLeft: "20px", marginTop: "20px" }} variant="outlined" value={content}
          onChange={(e) => setContent(e.target.value)} />
      </div>
      <div>
        <input
          accept="image/*"
          id="raised-button-file"
          multiple
          type="file"
          onChange={(e) => {
            uploadImage(e);
          }}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />
      </div>
      <div>
        <Button variant="contained" onClick={onClick}>Submit</Button>
        <div>{message}</div>
      </div>
    </div>
  );
}
